import React, { useState } from 'react';
import { connect } from 'react-redux';
import { reset_password_confirm } from '../actions/auth';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import { toast } from 'react-toastify';
import axios from 'axios';

const ResetPasswordConfirm = ({ reset_password_confirm, translation }) => {
    const [seePassword, setSeePassword] = useState(false);
    const [formData, setFormData] = useState({
        new_password: '',
        re_new_password: ''
    });

    const { new_password, re_new_password } = formData;

    const navigate = useNavigate();
    const { uid, token } = useParams();

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async e => {
        e.preventDefault();
        
        if (new_password !== re_new_password) {
            toast.error(translation.passwords_do_not_match || 'Passwords do not match');
            return;
        }

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const body = JSON.stringify({ uid, token, new_password, re_new_password });
            await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`, body, config);

            toast.success(translation.password_reset_successful || 'Password reset successful');
            navigate('/login');
        } catch(e) {
            toast.error(translation.password_reset_failed || 'Password reset failed');
        }
    };

    return (
        <div className='container mt-4 d-flex justify-content-center p-0'>
            <div className='login-section p-4'>
                <div className='d-flex justify-content-center mb-5'>
                    <img className='img-fluid'
                         src='https://streetfishing.fra1.cdn.digitaloceanspaces.com/media/assets/logo_orange_yellow_transparent.png'
                         alt='logo'/>
                </div>

                <h1 className='text-center'>
                    {translation.reset_password_title || 'Reset Password'}
                </h1>
                <p className='text-center mb-3'>
                    {translation.reset_password_subtitle || 'Enter your new password below'}
                </p>

                <form onSubmit={onSubmit}>
                    <div className='form-group position-relative'>
                        <input
                            className='login-input form-control mb-3'
                            type={seePassword ? 'text' : 'password'}
                            placeholder={translation.new_password || 'New Password'}
                            name='new_password'
                            value={new_password}
                            onChange={onChange}
                            minLength='6'
                            required
                        />
                        {seePassword ? (
                            <IoEyeOff
                                className='icon-password'
                                role='button'
                                onClick={() => setSeePassword(false)}
                            />
                        ) : (
                            <IoEye
                                className='icon-password'
                                role='button'
                                onClick={() => setSeePassword(true)}
                            />
                        )}
                    </div>

                    <div className='form-group position-relative'>
                        <input
                            className='login-input form-control'
                            type={seePassword ? 'text' : 'password'}
                            placeholder={translation.confirm_new_password || 'Confirm New Password'}
                            name='re_new_password'
                            value={re_new_password}
                            onChange={onChange}
                            minLength='6'
                            required
                        />
                        {seePassword ? (
                            <IoEyeOff
                                className='icon-password'
                                role='button'
                                onClick={() => setSeePassword(false)}
                            />
                        ) : (
                            <IoEye
                                className='icon-password'
                                role='button'
                                onClick={() => setSeePassword(true)}
                            />
                        )}
                    </div>
                    <Link className='btn btn-link text-decoration-none float-end' to='/login'>{translation.login}</Link>
                    <button className='btn green text-white w-100 mt-3 py-2' type='submit'>
                        {translation.reset_password_button || 'Reset Password'}
                    </button>
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    translation: state.appState.translation
});

const mapDispatchToProps = dispatch => ({
    reset_password_confirm: (uid, token, new_password, re_new_password) =>
        dispatch(reset_password_confirm(uid, token, new_password, re_new_password))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordConfirm);
