import { getBarer } from '../helpers/user';
import getAxiosConfig from '../utils/axiosConfig';
import {
    LOAD_EVENT,
    LOADED_EVENT,
    LOADED_EVENT_FAIL,
    LOAD_PUBLISH,
    LOADED_PUBLISH,
    LOADED_PUBLISH_FAIL,
    LOAD_EVENTS,
    LOADED_EVENTS,
    LOADED_EVENTS_FAIL,
    LOAD_TOURNAMENTS,
    LOADED_TOURNAMENTS,
    LOADED_TOURNAMENTS_FAIL,
    USER_LOADED_FAIL,
    SELECT_TOURNAMENT,
    SELECT_EVENT,
    POST_EVENT_SUCCESS,
    POST_EVENT_FAIL,
    POST_EVENT_LOADING,
    POST_EVENT_SCORING_SUCCESS,
    POST_EVENT_SCORING_FAIL,
    POST_EVENT_PRIZES_SUCCESS,
    POST_EVENT_PRIZES_FAIL,
    UPDATE_EVENT_SUCCESS,
    UPDATE_EVENT_SCORING_SUCCESS,
    LOAD_ENROLLMENTS_FAIL,
    LOAD_ENROLLMENTS_SUCCESS,
    LOAD_ENROLLMENTS_LOADING,
    LOAD_SCORING,
    LOAD_SCORING_FAIL,
    LOAD_CONTESTATII,
    LOAD_CONTESTATII_FAIL,
} from './types';
import axios from 'axios';

export const loadEvents = (_enroll) => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        try {
            dispatch({
                type: LOAD_EVENTS
            });

            const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/competitions/${_enroll ? 'event/my-events/' : 'events/'}`, config);
    
            dispatch({
                type: LOADED_EVENTS,
                payload: res.data.results.events
            });
        } catch (err) {
            dispatch({
                type: LOADED_EVENTS_FAIL
            });
        }
    } else {
        dispatch({
            type: LOADED_EVENTS_FAIL
        });
    }
};

export const loadEvent = (eventId) => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${getBarer()}`,
                'Accept': 'application/json'
            }
        };

        try {
            dispatch({
                type: LOAD_EVENT
            });

            const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/competitions/events/?id=${eventId}`, config);
    
            dispatch({
                type: LOADED_EVENT,
                payload: res.data.results.events[0]
            });
        } catch (err) {
            dispatch({
                type: LOADED_EVENT_FAIL
            });
        }
    } else {
        dispatch({
            type: LOADED_EVENT_FAIL
        });
    }
};

export const publishEvent = (eventId) => async dispatch => {
    const config = getAxiosConfig();
    if (Object.keys(config).length > 0) {
        const config = getAxiosConfig();

        try {
            dispatch({
                type: LOAD_PUBLISH
            });

            const res = await axios.post(`${process.env.REACT_APP_API_URL}/v2/event-publish/${eventId}/active/`, {}, config);
    
            dispatch({
                type: LOADED_PUBLISH,
                payload: res.data.results.events[0]
            });
        } catch (err) {
            dispatch({
                type: LOADED_PUBLISH_FAIL
            });
        }
    } else {
        dispatch({
            type: LOADED_PUBLISH_FAIL
        });
    }
};

export const loadTournaments = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        try {
            dispatch({
                type: LOAD_TOURNAMENTS
            });

            const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/competitions/tournaments/`, config);
    
            dispatch({
                type: LOADED_TOURNAMENTS,
                payload: res.data.tournaments
            });
        } catch (err) {
            dispatch({
                type: LOADED_TOURNAMENTS_FAIL
            });

            if(err.request.status === 401) {
                dispatch({
                    type: USER_LOADED_FAIL
                });
            }
            
        }
    } else {
        dispatch({
            type: LOADED_TOURNAMENTS_FAIL
        });
    }
};

export const selectTournament = (tournament) => async dispatch => {
    dispatch({
        type: SELECT_TOURNAMENT,
        payload: tournament
    });
};

export const selectEvent = (event) => async dispatch => {
    dispatch({
        type: SELECT_EVENT,
        payload: event
    });
};

export const postEvent = (formData) => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
            }
        };

        try {
            dispatch({
                type: POST_EVENT_LOADING
            });
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/v2/event/create/`, formData, config);
    
            dispatch({
                type: POST_EVENT_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: POST_EVENT_FAIL
            });

            if(err.request.status === 401) {
                dispatch({
                    type: USER_LOADED_FAIL
                });
            }
            
        }
    } else {
        dispatch({
            type: POST_EVENT_FAIL
        });
    }
};

export const postFishScoring = (formData) => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        try {
            dispatch({
                type: POST_EVENT_LOADING
            });
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/v2/event-fish-scoring/create/bulk/`, formData, config);
    
            dispatch({
                type: POST_EVENT_SCORING_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: POST_EVENT_SCORING_FAIL
            });

            if(err.request.status === 401) {
                dispatch({
                    type: USER_LOADED_FAIL
                });
            }
            
        }
    } else {
        dispatch({
            type: POST_EVENT_SCORING_FAIL
        });
    }
};

export const updateEvent = (formData) => async dispatch => {

    const config = getAxiosConfig();

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/v2/event/update/${formData.id}/`, formData, config);

        dispatch({
            type: UPDATE_EVENT_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        // dispatch({
        //     type: UPDATE_EVENT_FAIL
        // });

        if(err.request.status === 401) {
            dispatch({
                type: USER_LOADED_FAIL
            });
        }
        
    }
};

export const updateFishScoring = (formData) => async dispatch => {
    const config = getAxiosConfig();
    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/v2/event-fish-scoring/update/bulk/`, formData, config);
        dispatch({
            type: UPDATE_EVENT_SCORING_SUCCESS,
            payload: res.data
        });
    } catch (err) {

        if(err.request.status === 401) {
            dispatch({
                type: USER_LOADED_FAIL
            });
        }        
    }
};

export const postPrizes = (formData) => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        try {
            dispatch({
                type: POST_EVENT_LOADING
            });
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/v2/event-prizes-amount/bulk-create/`, formData, config);
    
            dispatch({
                type: POST_EVENT_PRIZES_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: POST_EVENT_SCORING_FAIL
            });

            if(err.request.status === 401) {
                dispatch({
                    type: USER_LOADED_FAIL
                });
            }
            
        }
    } else {
        dispatch({
            type: POST_EVENT_PRIZES_FAIL
        });
    }
};

export const loadEnrollments = (eventId) => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        try {
            dispatch({
                type: LOAD_ENROLLMENTS_LOADING
            });

            const res = await axios.get(`${process.env.REACT_APP_API_URL}/v2/event/enrolments/${eventId}/`, config);

            dispatch({
                type: LOAD_ENROLLMENTS_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: LOAD_ENROLLMENTS_FAIL
            });

            if(err.request.status === 401) {
                dispatch({
                    type: USER_LOADED_FAIL
                });
            }

        }
    } else {
        dispatch({
            type: LOAD_ENROLLMENTS_FAIL
        });
    }
};

export const loadScoring = (eventId) => async dispatch => {
    const config = getAxiosConfig();
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/events/${eventId}/rankings/`, config);
        
        dispatch({
            type: LOAD_SCORING,
            payload: res.data
        });
    } catch (err) {

        if(err.request.status === 401) {
            dispatch({
                type: LOAD_SCORING_FAIL
            });
        }        
    }
}


export const loadContestatii = (eventId) => async dispatch => {
    const config = getAxiosConfig();
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/v2/event/${eventId}/reports/`, config);
        
        dispatch({
            type: LOAD_CONTESTATII,
            payload: res.data
        });
    } catch (err) {

        if(err.request.status === 401) {
            dispatch({
                type: LOAD_CONTESTATII_FAIL
            });
        }        
    }
}