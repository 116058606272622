import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { signup } from "../actions/auth";
import TermsModal from "../components/TermsModal";
import { toast } from "react-toastify";
import axios from "axios";
import GDPRModal from "../components/GDPRModal";

const Signup = ({ signup, isAuthenticated, translation }) => {
  const [accountCreated, setAccountCreated] = useState(false);
  const navigate = useNavigate();
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showGDPR, setShowGDPR] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    } else if (accountCreated) {
      navigate("/");
    }
  }, [isAuthenticated, accountCreated, navigate]);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    re_password: "",
  });

  const { first_name, last_name, email, password, re_password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    if (password === re_password && agreeToTerms) {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        first_name,
        last_name,
        email,
        password,
        re_password,
      });

      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/users/`,
          body,
          config
        );
        setAccountCreated(true);
        toast.success(
          "Your account has been created. We have sent you an email for account activation."
        );
      } catch (err) {
        toast.error(
          err?.response?.data?.password?.toString() ||
            err?.response?.data?.email?.toString() ||
            "Your account couldn't be created."
        );
      }
    } else {
      toast.error("Please complete all fields.");
    }
  };

  const handleAgreeToTerms = (e) => {
    setAgreeToTerms(e.target.checked);
  };

  return (
    <>
      <div className="login-container mt-4 d-flex justify-content-center">
        <div className="login-section d-flex rounded justify-content-center flex-column px-4">
          <div className="d-flex w-100 justify-content-center">
            <img
              className="img-responsive w-100"
              src="https://streetfishing.fra1.cdn.digitaloceanspaces.com/media/assets/logo_orange_yellow_transparent.png"
              alt="logo"
            />
          </div>

          <h1 className="custom-title">{translation.signup_title}</h1>
          <p>{translation.signup_subtitle}</p>
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-group">
              <input
                className="login-input w-100 mt-2 py-2"
                type="text"
                placeholder={translation.first_name}
                name="first_name"
                value={first_name}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-group">
              <input
                className="login-input w-100 mt-2 py-2"
                type="text"
                placeholder={translation.last_name}
                name="last_name"
                value={last_name}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-group">
              <input
                className="login-input w-100 mt-2 py-2"
                type="email"
                placeholder={translation.email}
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-group">
              <input
                className="login-input w-100 mt-2 py-2"
                type="password"
                placeholder={translation.password}
                name="password"
                value={password}
                onChange={(e) => onChange(e)}
                minLength="6"
                required
              />
            </div>
            <div className="form-group">
              <input
                className="login-input w-100 mt-2 py-2"
                type="password"
                placeholder={translation.confirm_password}
                name="re_password"
                value={re_password}
                onChange={(e) => onChange(e)}
                minLength="6"
                required
              />
            </div>
            <div className="form-group form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="agreeToTerms"
                onChange={handleAgreeToTerms}
                required
              />
              <label className="form-check-label" htmlFor="agreeToTerms">
                {translation.agree_terms}
                <span
                  onClick={() => setShowTerms(true)}
                  style={{ cursor: "pointer", color: "blue" }}
                >
                  {" "}
                  {translation.terms_and_conditions}{" "}
                </span>
                ,
                <span
                  onClick={() => setShowGDPR(true)}
                  style={{ cursor: "pointer", color: "blue" }}
                >
                  {" "}
                  GDPR Policy{" "}
                </span>
              </label>
            </div>
            <button
              className="btn green text-white w-100 mt-3 py-2"
              type="submit"
            >
              {translation.register_cta}
            </button>
          </form>

          <TermsModal show={showTerms} onHide={() => setShowTerms(false)} />
          <GDPRModal show={showGDPR} onHide={() => setShowGDPR(false)} />

          {/*     */}

          <p className="mt-3">
            {translation.already_account}
            <Link to="/login">{translation.sign_in}</Link>
          </p>
          <p className="mt-3">
            Having troubles to register?{" "}
            <Link to="/contactus">{translation.contact_us_title}</Link>
          </p>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  translation: state.appState.translation,
});

export default connect(mapStateToProps, { signup })(Signup);
