import React from "react";
import termsRO from "./json/GDPR_ro.json";
const GDPRConditions = ({ language }) => {
  const terms = termsRO;
  const sections = Object.values(terms.sections);

  return (
    <div>
      <h1>{terms.title}</h1>
      {sections.map((section, index) => (
        <div key={index}>
          <h2>{section.title}</h2>
          <p>{section.content}</p>
        </div>
      ))}
    </div>
  );
};

export default GDPRConditions;
