import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { loadContestatii, loadEnrollments, loadEvent, loadScoring } from '../actions/eventsState';
import { loadAllCatchMap } from '../actions/myCatchMapDataState';
import { Modal } from 'react-bootstrap';
import Loader from '../components/Loader';
import axios from 'axios';
import { toast } from 'react-toastify';
import DropdownFishTypes from '../components/DropdownFishTypes';
import Input from '../components/Input';
import { Tabs, Tab, Button } from '@mui/material';
import { IoCheckmark, IoCheckmarkDoneSharp, IoEye } from 'react-icons/io5';
import { RefreshOutlined } from '@mui/icons-material';
import ScoringTable from '../components/ScoringTable';
import RevalidateTable from '../components/RevalidateTable';
import ReportsTable from '../components/tables/ReportsTable';

const EventValidate = ({ event, mapdata, loadEvent, loadAllCatchMap, user, isLoading, fish_types, scoring, loadScoring, translation, enrollments, loadEnrollments, contestatii, loadContestatii }) => {
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedFish, setSelectedFish] = useState(null);
    const [selectedFishType, setSelectedFishType] = useState(null);
    const [selectedFishLength, setSelectedFishLength] = useState(null);

    const { eventId } = useParams();
    const [showMapModal, setShowMapModal] = useState(null);
    console.log(contestatii)
    useEffect(() => {
        if(!eventId) {
            navigate('/');
        }
        loadScoring(eventId);
        loadEvent(eventId);
        loadAllCatchMap(eventId);
        loadEnrollments(eventId);
        loadContestatii(eventId);

    }, [loadEvent, eventId, navigate, loadAllCatchMap]);

    useEffect(() => {
        if(event) {
            const fishScoring = event.event_fish_scoring;
            setSelectedFish(fishScoring.map((item) => item.fish));
        }
    }, [event]);


    useEffect(() => {
        if(!event?.is_owner && !event?.validator === user.id) {
            navigate('/');
        }
    }, [event]);

    const refreshList = () => {
        loadAllCatchMap(eventId);
        loadScoring(eventId);
        loadContestatii(eventId);
    }

    const handleFishLengthChange = (event) => {
        setSelectedFishLength(event.target.value);
    }

    const handleFishType = (event) => {
        setSelectedFishType(event.target.value);
    }

    const updateCapture = async (item, status) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        setShowMapModal(null);
        try {
            const res = await axios.patch(`${process.env.REACT_APP_API_URL}/v2/event/captures-validate/${item.photo}/`, {
                fish_length: selectedFishLength,
                is_approved: status,
                fish: selectedFishType
            }, config);
            toast.success('Fish updated successfully!');
            refreshList();
        } catch(err) {
            toast.error('Error while updating fish!');
        }
    }

    return isLoading ? <Loader /> : (
        <>
            <div className={`page-container container-fluid bg-white`}>
                <div className='row gx-3 g-3'>
                        <Tabs
                            value={selectedTab}
                            onChange={(event, value) => setSelectedTab(value)}
                            variant="scrollable"
                            scrollButtons={false}
                            aria-label="scrollable prevent tabs example"
                        >
                            <Tab label="Validare capturi" id={0} tabIndex={0}/>
                            <Tab label="Revalidari" id="revalidate" value={1}/>
                            <Tab label="Clasament" id="scoring" value={2} />
                            <Tab label="Contestatii" id="reports" value={3} />
                        </Tabs>
                        <div
                            role="tabpanel"
                            hidden={selectedTab !== 0}
                            id={`simple-tabpanel-${selectedTab}`}
                            aria-labelledby={`simple-tab-${selectedTab}`}
                        >
                            <Button variant="outlined" color="primary" className='float-end mb-3' onClick={() => refreshList()}>
                                <RefreshOutlined sx={{ mr: 1 }}/>
                                Refresh
                            </Button>

                            <table className='table table-hover table-catch w-100 mt-3'>
                                <thead>
                                    <tr>
                                        <th className="galben text-white">User</th>
                                        <th className="galben text-white">Fish</th>
                                        <th className="galben text-white">Length</th>
                                        <th className="galben text-white">Status</th>
                                        <th className="galben text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { mapdata?.map((item, key) => {
                                        return (
                                            <tr key={key}>
                                                <td className="">{item.full_name}</td>
                                                <td className="position-relative">
                                                    {item.fish_name}
                                                    <span className='position-absolute catch-hour'>
                                                        {new Date(item.upload_date).getHours() > 9 ? new Date(item.upload_date).getHours() : `0${new Date(item.upload_date).getHours()}`}:{new Date(item.upload_date).getMinutes() > 9 ? new Date(item.upload_date).getMinutes() : `0${new Date(item.upload_date).getMinutes()}`}</span>    
                                                </td>
                                                <td className="">{item.fish_length}</td>
                                                <td className="">{item.fish_status ? <IoCheckmarkDoneSharp className='color-green' /> : <IoCheckmark className="color-galben" />}</td>
                                                <td className="">
                                                    <IoEye role="button" onClick={() => {setShowMapModal(item);setSelectedFishLength(item.fish_length);setSelectedFishType(item.fish_id);}} />
                                                </td>
                                            </tr>
                                        );
                                    }) }
                                </tbody>
                                
                            </table>
                        </div>
                        <div
                            role="tabpanel"
                            hidden={selectedTab !== 1}
                            id={`simple-tabpanel-${selectedTab}`}
                            aria-labelledby={`simple-tab-${selectedTab}`}
                        >
                            <RevalidateTable enrollments={enrollments.results} refreshList={refreshList} />
                        </div>
                        <div
                            role="tabpanel"
                            hidden={selectedTab !== 2}
                            id={`simple-tabpanel-${selectedTab}`}
                            aria-labelledby={`simple-tab-${selectedTab}`}
                        >
                            <Button variant="outlined" color="primary" className='float-end mb-3' onClick={() => refreshList()}>
                                <RefreshOutlined sx={{ mr: 1 }}/>
                                Refresh
                            </Button>

                            <ScoringTable scoring={scoring} event={event}/>
                        </div>

                        <div
                            role="tabpanel"
                            hidden={selectedTab !== 3}
                            id={`simple-tabpanel-${selectedTab}`}
                            aria-labelledby={`simple-tab-${selectedTab}`}
                        >
                            <Button variant="outlined" color="primary" className='float-end mb-3' onClick={() => refreshList()}>
                                <RefreshOutlined sx={{ mr: 1 }}/>
                                Refresh
                            </Button>

                            <ReportsTable data={contestatii} refreshReports={refreshList}/>
                        </div>
                </div>
            </div>
            <Modal show={showMapModal ? true : false} onHide={() => setShowMapModal(null)} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Captura</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showMapModal && (
                        <>
                            <div className='d-flex'>
                                <button className='btn red text-white float-start m-0'
                                        onClick={() => updateCapture(showMapModal, false)}>Reject
                                </button>
                                <button className='ms-auto btn blue text-white'
                                        onClick={() => updateCapture(showMapModal, true)}>Approve
                                </button>
                            </div>
                            <Input
                                type="text"
                                className="form-control text-start"
                                name={translation.no_player}
                                label={translation.no_player}
                                value={enrollments?.results?.findIndex(item => item.user === showMapModal?.user_id) + 1}
                                onChange={undefined}
                                disabled
                                readOnly
                            />
                            <Input
                                type="text"
                                className="form-control text-start"
                                name={translation.phone_number}
                                label={translation.phone_number}
                                value={enrollments?.results?.find(item => item.user === showMapModal?.user_id).user_phone}
                                onChange={undefined}
                                disabled
                                readOnly
                            />
                            <DropdownFishTypes
                                type="text"
                                className="form-control text-start"
                                name={translation.fish_type}
                                label={translation.fish_type}
                                options={fish_types?.filter((item) => selectedFish?.includes(item.id))}
                                selectedOptions={selectedFishType}
                                onSelect={handleFishType}
                            />
                            <Input
                                type="number"
                                className="form-control step"
                                name={`Fish Length`}
                                label={translation.ong_fish_length}
                                placeholder={translation.ong_fish_length}
                                onChange={handleFishLengthChange}
                                value={selectedFishLength}
                            />
                            <img className='w-100' src={showMapModal?.photo_url} alt=""/>
                            <Modal.Title className='my-3 fs-1'>{translation.other_photo}</Modal.Title>

                            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="false">
                                <div className="carousel-inner">
                                    {scoring?.map(item => {
                                        if (event?.is_team_event) {
                                            if (item.team_id !== showMapModal.team_id) return null;
                                        } else {
                                            if (item.user_id !== showMapModal.user_id) return null;
                                        }
                                        return (
                                            item.details.map((detail, index) =>
                                                <div className={`carousel-item ${index === 0 && 'active'}`}
                                                     key={`captura_${index}`}>
                                                    <img className="d-block w-100" src={detail.photo_url} alt=""/>
                                                </div>
                                            )
                                        );
                                    })}
                                </div>
                                <button className="carousel-control-prev" type="button"
                                        data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button"
                                        data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>


                            {/* {showMapModal?.gps_latitude !== 0 && showMapModal?.gps_longitude !== 0 && (
                                <>
                                    <Modal.Title className='my-3'>Map</Modal.Title>
                                    <MyEventCatchMap 
                                        lat={showMapModal?.gps_latitude}
                                        lng={showMapModal?.gps_longitude}
                                        event_name={showMapModal?.event_name}
                                        fish_name={showMapModal?.fish_name}
                                        photo_url={showMapModal?.photo_url}
                                        fish_length={showMapModal?.fish_length}
                                    />
                                </>
                            )} */}

                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
}

const mapStateToProps = state => ({
    event: state.eventState.event,
    eventLoading: state.eventState.eventLoading,
    mapdata: state.myMapCatchDataReducer.mapdata,
    isLoading: state.myMapCatchDataReducer.loading,
    fish_types: state.utilsState.fish_types,
    translation: state.appState.translation,
    user: state.auth.user,
    scoring: state.eventsState.scoring,
    enrollments: state.eventState.enrollments,
    contestatii: state.eventsState.contestatii,
});

const mapDispatchToProps = (dispatch) => {
    return {
        loadEvent: (eventId) => {
            dispatch(loadEvent(eventId));
        },
        loadScoring: (eventId) => {
            dispatch(loadScoring(eventId));
        },
        loadAllCatchMap: (eventId) => {
            dispatch(loadAllCatchMap(eventId));
        },
        loadEnrollments: (eventId) => {
            dispatch(loadEnrollments(eventId));
        },
        loadContestatii: (eventId) => {
            dispatch(loadContestatii(eventId));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EventValidate));

