import { Box } from "@mui/material";
import FishLoader from "../FishLoader";

export const AppLoader = ( {show} ) => {
    if(!show) return null;

    return (
        <Box component="div"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'fixed',
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                height: '100vh',
                width: '100vw',
                left: 0,
                top: 0,
                zIndex: 3200,
            }}
        >
            <FishLoader />
        </Box>
    )
}