import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";
import getAxiosConfig from "../utils/axiosConfig";
import Loader from "./Loader";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";
import {
  ArrowCircleDownOutlined,
  ArrowCircleUpOutlined,
  InfoOutlined,
  StopCircleOutlined,
} from "@mui/icons-material";

const RankingMovement = () => {
  const { eventId } = useParams();
  const [rankings, setRankings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const prevRankingsRef = useRef([]);

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/events/${eventId}/rankings/movements/`,
          getAxiosConfig()
        );
        const newRankings = res.data;

        const prevRankings = prevRankingsRef.current;

        const updatedRankings = newRankings.map((user) => {
          const identifier = user.user || user.team;
          const prevUser = prevRankings.find(
            (u) => (u.user || u.team) === identifier
          );
          const rankChanged =
            prevUser && prevUser.current_rank !== user.current_rank;
          const pointsChanged =
            prevUser && prevUser.total_points !== user.total_points;
          return {
            ...user,
            rankChanged,
            pointsChanged,
          };
        });

        setRankings(updatedRankings);
        prevRankingsRef.current = newRankings;
        setLoading(false);
      } catch (err) {
        if (err.response && err.response.status === 429) {
          toast.error(err.response.data.detail);
        } else {
          toast.error("Failed to fetch rankings.");
        }
        setLoading(false);
      }
    };

    fetchRankings();
    // Set interval to refresh the rankings every 30 seconds
    const interval = setInterval(() => {
      fetchRankings();
    }, 30000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [eventId]);

  const getRank = (event) => {
    console.log(event);
    if (event.current_rank < event.previous_rank) {
      return <ArrowCircleUpOutlined color="success" />;
    }
    if (event.current_rank > event.previous_rank) {
      return <ArrowCircleDownOutlined color="error" />;
    }

    if (event.current_rank === event.previous_rank) {
      return <StopCircleOutlined color="primary" />;
    }
  };

  if (loading) return <Loader />;
  if (error) return <p>{error}</p>;

  return (
    <motion.div layout style={{ padding: "10px" }}>
      <Typography variant="subtitle2" color="warning" marginBottom={2}>
        <InfoOutlined /> Scorul va fi actualizat automat la fiecare 30 de
        secunde.
      </Typography>
      {rankings.map((user) => (
        <motion.div
          key={user.user || user.team}
          layout
          animate={{
            backgroundColor:
              user.rankChanged || user.pointsChanged
                ? ["#ffffff", "#ffff99", "#ffffff"]
                : "#ffffff",
          }}
          transition={{
            backgroundColor:
              user.rankChanged || user.pointsChanged
                ? { duration: 1 }
                : { duration: 0 },
            layout: {
              duration: 0.5,
              type: "spring",
              stiffness: 300,
              damping: 30,
            },
          }}
          className="ranking-item"
        >
          {/* Display rank, user/team name, and points in one row */}
          <div className="ranking-row">
            <span className="user-rank">{user.current_rank}.</span>
            <span className="user-name">{user.user || user.team}</span>
            <span className="user-points">{getRank(user)}</span>
            <span
              className="user-points"
              style={{
                display: "inline-block",
                minWidth: "30px",
                textAlign: "right",
              }}
            >
              {user.total_points}
            </span>
          </div>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default RankingMovement;
