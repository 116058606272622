import { useField } from "formik";
import { TextField } from "@mui/material";

export const AppTextField = ({ onChange, validate, name, maxLength, ...props }) => {
    
    const [field, meta] = useField({ name, validate });
    const hasError = (meta.touched && meta.error) ? true : false ;
    
    return (
        <TextField
            {...field}
            {...props}
            variant="outlined"
            fullWidth
            onChange={onChange}
            inputProps={{ maxLength: maxLength }}
            error={hasError}
            helperText={hasError ? meta.error : ''}
            sx={{
                '& .MuiOutlinedInput-root': {
                    '& input:-webkit-autofill:focus': {
                        WebkitBoxShadow: '0 0 0 1000px white inset',
                    },
                    '& input:-webkit-autofill': {
                        WebkitTextFillColor: 'black',
                        WebkitBoxShadow: '0 0 0 1000px white inset',
                    }
                },
                bgcolor: '#fff',
                flexGrow: '0'
            }}
        />
    )
}