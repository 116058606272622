import React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

// Bottom-up Transition Component
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppDialog = ({ open, handleClose, children, title }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-describedby="bottom-up-dialog-description"
      fullScreen
      sx={{ pt: 5 }}
    >
        <AppBar color='primary'>
            <Toolbar>
                <Typography variant='h6' component="div">
                    {title}
                </Typography>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    sx={{ ml: 'auto' }}
                >
                    <Close />
                </IconButton>
            </Toolbar>
        </AppBar>
        {children}
    </Dialog>
  );
};

export default AppDialog;