import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import GDPRConditions from "../containers/GDPRConditions";

const GDPRModal = ({ show, onHide }) => {
  const [termsLanguage, setTermsLanguage] = useState("en");

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Politica GDPR</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GDPRConditions language={termsLanguage} />
      </Modal.Body>
      <Modal.Footer>
        <button className="btn green text-white mt-3" onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default GDPRModal;
