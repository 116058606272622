import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { loadEvent, loadScoring } from '../actions/eventsState';
import { loadMyCatchMapData } from '../actions/myCatchMapDataState';
import Loader from '../components/Loader';
import axios from 'axios';
import { toast } from 'react-toastify';
import { IoCheckmark, IoCheckmarkDoneSharp, IoClose, IoCloudUploadOutline, IoEye } from 'react-icons/io5';
import FishLoader from '../components/FishLoader';
import ScoringTable from '../components/ScoringTable';
import { uploadCaptureSchema } from '../utils/validationSchema';
import { Field, Form, Formik } from 'formik';
import { Box, Button, Grid2, Typography } from '@mui/material';
import { AppTextField } from '../components/common/AppTextField';
import { AppSelect } from '../components/common/AppSelect';
import AppDialog from '../components/common/AppDialog';

const EventOngoing = ({ event, translation, mapdata, loadEvent, loadMyCatchMapData, fish_types, loadScoring, scoring, user }) => {
    const navigate = useNavigate();
    const ref = useRef();
    const { eventId } = useParams();
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [selectedFish, setSelectedFish] = useState(null);
    const [showMapModal, setShowMapModal] = useState(null);
    const [loading, setLoading] = useState(false);
    const [scoringView, setScoringView] = useState(false);
    const [location, setLocation] = useState({ latitude: null, longitude: null });
    const [locationError, setLocationError] = useState(null);

    useEffect(() => {
        if(!eventId) {
            navigate('/');
        }

        loadEvent(eventId);
        loadScoring(eventId);
        loadMyCatchMapData(eventId);
    }, [eventId]);


    useEffect(() => {
        if (
            event && 
            (
                !event?.is_ongoing || 
                (
                    event?.enrollement_status === 'not enrolled' || 
                    event?.enrollement_status === 'rejected'
                )
            )
        ) {
            navigate('/');
        }
    }, [event]);

    useEffect(() => {
        if(event) {
            const fishScoring = event.event_fish_scoring;
            setSelectedFish(fishScoring.map((item) => item.fish));
        }
    }, [event]);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                    setLocationError(null);
                },
                (error) => {
                    setLocationError('Location access denied or unavailable. Defaulting to null coordinates.');
                    setLocation({ latitude: null, longitude: null });
                }
            );
        } else {
            setLocationError('Geolocation is not supported by this browser. Defaulting to null coordinates.');
            setLocation({ latitude: null, longitude: null });
        }

    }, []);


    const handlePhotoInput = (event) => {
        const file = event.target.files[0];
        setSelectedPhoto(file);
        event.target.value = '';
    }

    const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
        setLoading(true);


        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        };

        const formattedLatitude = (location.latitude !== null) ? location.latitude.toFixed(7) : "0.0000000";
        const formattedLongitude = (location.longitude !== null) ? location.longitude.toFixed(7) : "0.0000000";

        const formData = new FormData();
        formData.append('photo', selectedPhoto);
        formData.append('event', eventId);
        formData.append('fish', values.fish);
        formData.append('fish_length', values.fish_length);
        formData.append('latitude', formattedLatitude);
        formData.append('longitude', formattedLongitude);

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/v2/event/upload-photo/`, formData, config);
            toast.success('You have uploaded a fish');
            loadMyCatchMapData(eventId);
            resetForm();
            setSelectedPhoto(null);
            setLoading(false);
        } catch (error) {
            if (error.response && error.response.data) {
                if (error.response.data.detail) {
                    toast.error(error.response.data.detail);
                } else if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error('An error occurred');
                }
            }
            setLoading(false);
        }
    };

  const handleUpload = () => {
    ref.current.click();
  }

  const handleDeletePhoto = () => {
    setSelectedPhoto(null);
  }

  return (
    <div className={`page-container container-fluid bg-white`}>
        <div className='row gx-3 g-3'>
            {(event && mapdata) ? (
                <>
                    <h5>{event.event_name}</h5>
                    <Formik
                        initialValues={{fish: '', fish_length: ''}}
                        validationSchema={uploadCaptureSchema}
                        onSubmit={handleFormSubmit}
                        enableReinitialize
                        validateOnMount={true}
                    >
                        {({ isSubmitting, submitForm, isValid, dirty, errors, values }) => (
                            <Form>
                                <Grid2 container>
                                    <Grid2 size={12}>
                                        {!selectedPhoto ? (                                
                                        <div className='upload-watermark text-center mb-2' onClick={handleUpload} role="button">
                                            {translation.ong_upload} <IoCloudUploadOutline className='fs-5' />
                                        </div>
                                        ) : (
                                            <div className='event-actions text-center mb-2 position-relative pe-4'>
                                                <img className='image-upload-capture' src={URL.createObjectURL(selectedPhoto)} alt="" style={{filter: loading ? 'blur(8px)' : 'none'}} />
                                                <IoClose className='fs-1 photo-delete-icon position-absolute color-red end-0 cursor-pointer' onClick={handleDeletePhoto} />
                                                {loading && (
                                                    <div style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <FishLoader />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <input
                                            type={`file`}
                                            accept="image/*"
                                            className={`form-control step d-none`}
                                            ref={ref}
                                            name={translation.ong_upload}
                                            label={translation.ong_upload}
                                            onChange={(e) => handlePhotoInput(e)}
                                        />
                                    </Grid2>
                                    <Grid2 size={12} sx={{ my: 3 }}>
                                        <Field
                                            name="fish"
                                            as={AppSelect}
                                            type={`number`}
                                            label="Specia"
                                            size="small"
                                            options={fish_types?.filter((item) => selectedFish?.includes(item.id)).map(fish => ({ value: fish.id, label: fish.name}))}
                                        />
                                    </Grid2>
                                    <Grid2 size={12} sx={{ mb: 3 }}>
                                        <Field
                                            name="fish_length"
                                            as={AppTextField}
                                            type={`number`}
                                            label="Lungimea capturii"
                                            size="small"
                                        />
                                    </Grid2>
                                    <Button
                                        disabled={!isValid || !dirty || isSubmitting || !selectedPhoto}
                                        variant={`contained`}
                                        color={`success`}
                                        type={`submit`}
                                    >
                                        {translation.upload_fish}
                                    </Button>
                                </Grid2>
                            </Form>

                        )}
                    </Formik>
                    <button 
                        className='btn blue text-white' 
                        onClick={() => setScoringView(!scoringView)} 
                        disabled={!scoring}
                    >
                        {scoringView ? translation.cls_total_caught : translation.ranking}
                    </button>
                    {!scoringView ? (
                        <table className='table table-hover table-catch w-100 mt-3'>
                        <thead>
                            <tr>
                                <th className="galben text-white">{translation.cls_fish}</th>
                                <th className="galben text-white">{translation.cls_length}</th>
                                <th className="galben text-white">{translation.cls_status}</th>
                                <th className="galben text-white"></th>
                            </tr>
                        </thead>
                        <tbody>
                            { mapdata?.map((item, key) => {
                                return (
                                    <tr key={key}>
                                        <td className="position-relative">
                                            {item.fish_name}
                                            <span className='position-absolute catch-hour'>{new Date(item.upload_date).getHours() > 9 ? new Date(item.upload_date).getHours() : `0${new Date(item.upload_date).getHours()}`}:{new Date(item.upload_date).getMinutes() > 9 ? new Date(item.upload_date).getMinutes() : `0${new Date(item.upload_date).getMinutes()}`}</span>
                                        </td>
                                        <td className="">{item.fish_length} cm</td>
                                        <td className="">{item.fish_status ?  <IoCheckmarkDoneSharp className="color-green" /> : (item.is_rejected ? <IoClose className='color-red' /> : <IoCheckmark className='color-galben' />)}</td>
                                        <td className=""><IoEye role="button" onClick={() => setShowMapModal(item)} /></td>
                                    </tr>
                                );
                            }) }
                        </tbody>
                        
                        </table>
                    ) : (
                        <ScoringTable scoring={scoring} event={event} />
                    )}
                </>
            ): (
                <Loader />
            )}
            <AppDialog open={!!showMapModal} handleClose={() => setShowMapModal(null)} title={`Captura`}>
                <Box sx={{ px: 2, pb: 3, display: 'flex', flexDirection: 'column' }}>
                    <img className='w-100 img-capture mt-5 mb-2 mh-50 mx-auto' src={showMapModal?.photo_url} alt=""/>
                    <Typography variant="subtitle1">
                        <b>{translation.fish}</b>: {showMapModal?.fish_name}
                    </Typography>
                    <Typography variant="subtitle1">
                        <b>{translation.cls_length}</b>: {showMapModal?.fish_length} cm
                    </Typography>
                </Box>
            </AppDialog>
        </div>
    </div>
  );
}

const mapStateToProps = state => ({
    event: state.eventState.event,
    eventLoading: state.eventState.eventLoading,
    mapdata: state.myMapCatchDataReducer.mapdata,
    fish_types: state.utilsState.fish_types,
    translation: state.appState.translation,
    scoring: state.eventsState.scoring,
    user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
     loadEvent: (eventId) => {
      dispatch(loadEvent(eventId))
    },
    loadMyCatchMapData: (eventId) => {
        dispatch(loadMyCatchMapData(eventId));
    },
    loadScoring: (eventId) => {
        dispatch(loadScoring(eventId));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EventOngoing));

