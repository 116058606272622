import * as Yup from "yup";

export const uploadCaptureSchema = Yup.object({
    fish: Yup.number().required("Alege o specie."),
    fish_length: Yup.number().required("Specifica lungimea capturii.")
});

export const contestatieSchmea = Yup.object({
    reason: Yup.string().required('Specifica un motiv pentru contestatie')
});

export const contestatiePatchSchmea = Yup.object({
    status: Yup.string().required('Specifica o rezolutie pentru contestatie')
});