import React, { useEffect, useState } from 'react';
import { IoCaretDownOutline, IoFishSharp } from 'react-icons/io5';
import { connect } from 'react-redux';
import AppDialog from './common/AppDialog';
import { Box, Button, Grid2, Typography } from '@mui/material';
import { EditCalendarOutlined } from '@mui/icons-material';
import { Field, Form, Formik } from 'formik';
import { contestatieSchmea } from '../utils/validationSchema';
import { AppLoader } from './common/AppLoader';
import { AppTextField } from './common/AppTextField';
import { toast } from 'react-toastify';
import axios from 'axios';
import getAxiosConfig from '../utils/axiosConfig';

const ScoringTable = ({ scoring, event, translation, userId = false }) => {
    const [expand, setExpand] = useState(-1);
    const [biggestCatch, setBiggestCatch] = useState(null);
    const [fishNameCatch, setFishNameCatch] = useState(null);
    const [tableScoring, setTableScoring] = useState(scoring);
    const [contestatie, setContestatie] = useState(false);
    const [modalView, setModalView] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(!scoring || !scoring.length) return;
        const filterBiggestCatch = scoring.reduce((prev, current) => (prev && prev.biggest_catch > current.biggest_catch) ? prev : current, scoring[0]);
        setBiggestCatch(filterBiggestCatch);
        setFishNameCatch(filterBiggestCatch?.details.find(item => filterBiggestCatch.biggest_catch === item.length));
    }, [scoring]);

    useEffect(() => {
        if(!userId) return;
        let getPositionIndex = scoring.findIndex(item => item.user === userId);
        if(getPositionIndex === 0) {
            getPositionIndex = 1;
        } else if(getPositionIndex + 1 === scoring.length) {
            getPositionIndex -= 1;
        }

        const tableScoringMap = scoring.filter((item, index) => (index === getPositionIndex) || (index > getPositionIndex && index - 1 === getPositionIndex) || (index < getPositionIndex && index + 1 === getPositionIndex));
        setTableScoring(tableScoringMap);
    }, [userId]);

    const submitContestatie = async (values) => {
        const config = getAxiosConfig();
        try {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_API_URL}/v2/event/${event.id}/photos/${modalView.photo_id}/report/`, {reason: values.reason}, config);
            toast.success('Contestatie trimisa cu success');
        } catch (err) {
            toast.error(err?.response?.data?.error || 'Contestatia nu a putut fi trimisa');      
        } finally {
            setContestatie(false);
            setLoading(false);
        }
    }

    const renderCatch = (item, key) => {
        return (
            <tr key={`catchData${key}`} onClick={() => setModalView(item)} className='cursor-pointer'>
                <td></td>
                <td></td>
                <td className="position-relative">
                    {item.fish_name}
                    <span className='position-absolute catch-hour'>
                        {new Date(item.upload_date).getHours() > 9 ? new Date(item.upload_date).getHours() :`0${new Date(item.upload_date).getHours()}`}:{new Date(item.upload_date).getMinutes() > 9 ? new Date(item.upload_date).getMinutes() :`0${new Date(item.upload_date).getMinutes()}`} {item.entity_initials}
                    </span>
                </td>
                <td className=""></td>
                <td className=""></td>
                <td className="">{item.length} cm</td>
                <td className="">{item.points}</td>
                <td>{item.is_valid_fish ? (<IoFishSharp className="color-green" />) : (<IoFishSharp className='color-red' />)}</td>
            </tr>
        )
    }

    const renderRow =(item, key) => {
        return (
            <>
                <tr key={`rowData${key}`}>
                    <td><IoCaretDownOutline className={`color-green ${key === expand ? 'rotate-180' : ''}`} role="button" onClick={(event) => {setExpand(key === expand ? -1 : key )}}/></td>
                    <td className="">{item.is_team_event ? item.team_rank : item.user_rank}</td>
                    <td className="">{item.is_team_event ? item.team_name : item.user_name}</td>
                    <td className="">{item.total_calculated_fishes}</td>
                    <td className="">
                        {item.total_points} {event?.has_bonus_points && <small>({item.bonus_points})</small>}
                    </td>
                    <td className="">
                        {item.total_species}
                    </td>
                    <td className="">
                        {item.biggest_catch} cm
                    </td>
                    <td className="">
                        {item.average_catch} cm
                    </td>
                    
                </tr>
                {expand > -1 && expand === key && (
                    <>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>{translation.cls_fish}</th>
                            <th></th>
                            <th></th>
                            <th>{translation.cls_length}</th>
                            <th>{translation.cls_points}</th>
                            <th>{translation.cls_valid}</th>
                        </tr>
                            
                        {item.details.map(renderCatch)}
                    </>
                    
                )}
            </>
            
        );
    };

    return (
        <>
            <table className='table table-hover table-catch w-100 mt-3'>
                <thead>
                    <tr>
                        <th className="galben text-white"></th>
                        <th className="galben text-white">{translation.cls_rank}</th>
                        <th className="galben text-white">{translation.cls_name}</th>
                        <th className="galben text-white">{translation.cls_total_caught}</th>
                        <th className="galben text-white">{translation.cls_points}</th>
                        <th className="galben text-white">{translation.cls_fish}</th>
                        <th className="galben text-white">{translation.cls_cmmc}</th>
                        <th className="galben text-white">{translation.cls_average}</th>
                    </tr>
                </thead>
                <tbody>
                    {biggestCatch && fishNameCatch && <tr>
                        <td></td>
                        <td className="">{translation.cls_cmmc}</td>
                        <td className="">{biggestCatch.is_team_event? biggestCatch.team_name : biggestCatch.user_name}</td>
                        <td className=""></td>
                        <td className="">
                            
                        </td>
                        <td className="">
                            {fishNameCatch.fish_name}
                        </td>
                        <td className="">
                            {biggestCatch.biggest_catch} cm
                        </td>
                        <td className="">
                            
                        </td>
                        
                    </tr>}
                    {scoring?.map(renderRow)}
                </tbody>
            </table>

            <AppDialog open={!!modalView} handleClose={() => setModalView(null)} title={`Captura`}>
                <Box sx={{ px: 2, pb: 3, display: 'flex', flexDirection: 'column' }}>
                    <img className='w-100 img-capture mt-5 mb-2 mh-50 mx-auto' src={modalView?.photo_url} alt=""/>
                    
                    <Button variant="outlined" color="success" sx={{ml: 'auto'}} onClick={() => setContestatie(true)} disabled={event?.enrollment_status !== 'approved'}>
                        <EditCalendarOutlined sx={{ mr: 1 }}/>
                        {translation.contestatie}
                    </Button>

                    <Typography variant="subtitle1">
                        <b>{translation.fish}</b>: {modalView?.fish_name}
                    </Typography>
                    <Typography variant="subtitle1">
                        <b>{translation.cls_length}</b>: {modalView?.length} cm
                    </Typography>
                    <Typography variant="subtitle1">
                        <b>{translation.cls_points}</b>: {modalView?.points}
                    </Typography>
                </Box>
            </AppDialog>

            <AppDialog open={!!contestatie} handleClose={() => setContestatie(null)} title={`Contestatie`}>
                <Box sx={{ px: 2, mt: 5, display: 'flex', flexDirection: 'column' }}>
                    <Formik
                        initialValues={{ reason: '' }}
                        onSubmit={submitContestatie}
                        validationSchema={contestatieSchmea}
                    >
                        <Form>
                            <Grid2 xs={12} sx={{ mb: 3 }}>
                                <Field
                                    name="reason"
                                    as={AppTextField}
                                    rows={4}
                                    label="Motivul contestatiei"
                                    size="small"
                                    multiline
                                />
                            </Grid2>
                            <Button variant='contained' color='success' type='submit'>
                                Trimite contestatia
                            </Button>
                        </Form>
                    </Formik>
                </Box>
            </AppDialog>
            <AppLoader show={loading} />
        </>
    );
}


const mapStateToProps = state => ({
    translation: state.appState.translation,
});

export default connect(mapStateToProps, null)(ScoringTable);