import React, { useState } from "react";
import {
  Box,
  Button,
  Grid2,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AppDialog from "../common/AppDialog";
import { AppSelect } from "../common/AppSelect";
import { contestatiePatchSchmea } from "../../utils/validationSchema";
import { Field, Form, Formik } from "formik";
import { AppTextField } from "../common/AppTextField";
import axios from "axios";
import getAxiosConfig from "../../utils/axiosConfig";
import { toast } from "react-toastify";

const ReportsTable = ({ data, refreshReports }) => {
  const [viewReport, setViewReport] = useState(null);

  const changeStatus = async (values) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/event/${viewReport.event}/reports/${viewReport.id}/`,
        values,
        getAxiosConfig()
      );
      toast.success("Contestatie updated");
      setViewReport(null);
      refreshReports();
    } catch (e) {
      toast.error("Contestatie updated");
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Reporter</TableCell>
              <TableCell>Reported</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.reports?.map((report, index) => (
              <TableRow
                key={index}
                onClick={() => setViewReport(report)}
                className="cursor-pointer"
              >
                <TableCell>{report.reporter_name}</TableCell>
                <TableCell>{report.reported_user_name}</TableCell>
                <TableCell sx={{ textTransform: "capitalize" }}>
                  {report.status.split("_").join(" ")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AppDialog
        open={!!viewReport}
        handleClose={() => setViewReport(null)}
        title={`Contestatie`}
      >
        <Box sx={{ px: 2, pb: 3, display: "flex", flexDirection: "column" }}>
          <img
            className="w-100 mt-5 mb-2 mh-50 img-capture mx-auto"
            src={viewReport?.photo_url}
            alt=""
          />
          <Typography variant="subtitle1" sx={{ my: 1 }}>
            <b>Status</b>: {viewReport?.status}
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            <b>Motiv</b>: {viewReport?.reason}
          </Typography>
          {data.can_validate && viewReport && (
            <Formik
              initialValues={{
                ...viewReport,
                action_taken: viewReport.action_taken
                  ? viewReport.action_taken
                  : "",
                penalty_points: viewReport.penalty_points
                  ? viewReport.penalty_points
                  : 0,
              }}
              validateSchema={contestatiePatchSchmea}
              onSubmit={changeStatus}
              enableReinitialize
              validateOnMount
            >
              {({ dirty, values }) => (
                <Form>
                  <Grid2 xs={12} sx={{ mb: 3 }}>
                    <Field
                      name="status"
                      as={AppSelect}
                      label="Statusul contestatiei"
                      size="small"
                      options={[
                        { value: "noua", label: "Noua" },
                        { value: "in_analiza", label: "In analiza" },
                        { value: "acceptata", label: "Acceptata" },
                        { value: "respinsa", label: "Respinsa" },
                      ]}
                    />
                  </Grid2>
                  <Grid2 xs={12} sx={{ mb: 3 }}>
                    <Field
                      name="action_taken"
                      as={AppTextField}
                      label="Actiunea contestatiei"
                      size="small"
                    />
                  </Grid2>
                  {values.status === "acceptata" && (
                    <Grid2 xs={12} sx={{ mb: 3 }}>
                      <Field
                        name="penalty_points"
                        as={AppTextField}
                        type={`number`}
                        label="Puncte penalizare"
                        size="small"
                      />
                    </Grid2>
                  )}
                  <Button
                    type="submit"
                    disabled={!dirty}
                    variant="contained"
                    color="success"
                    sx={{ ml: "auto" }}
                  >
                    Schimba statusul
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </Box>
      </AppDialog>
    </>
  );
};

export default ReportsTable;
