import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../actions/auth';
import TermsModal from "../components/TermsModal";
import { requestUserPermission } from '../utils/firebase';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import { IoEye, IoEyeOff } from 'react-icons/io5';

const Login = ({ login, isAuthenticated, failedAuth, translation }) => {
    const navigate = useNavigate();
    const [showTerms, setShowTerms] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [seePassword, setSeePassword] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
    const recaptchaRef = useRef(null);

    useEffect(() => {
        if (isAuthenticated) {
            requestUserPermission();
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        if (failedAuth) {
            recaptchaRef.current.reset();
            setCaptchaValue(null);
        }
    }, [failedAuth]);

    const handleLogin = event => {
        event.preventDefault();
        if (captchaValue) {
            login(email, password, captchaValue);
        } else {
            toast.warn('Please verify that you are a human!');
        }
    };

    return (
        <div className='container mt-4 d-flex justify-content-center p-0'>
            <div className='login-section p-4'>
                <div className='d-flex justify-content-center mb-5'>
                    <img className='img-fluid'
                         src='https://streetfishing.fra1.cdn.digitaloceanspaces.com/media/assets/logo_orange_yellow_transparent.png'
                         alt='logo'/>
                </div>

                <h1 className='text-center'>{translation.login_title}</h1>
                <p className='text-center mb-3'>{translation.login_subtitle}</p>
                <form onSubmit={handleLogin}>
                    <div className='form-group'>
                        <input
                            className='login-input form-control mb-2'
                            type='email'
                            placeholder={translation.email}
                            autoComplete='email'
                            name='email'
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className='form-group position-relative'>
                        <input
                            className='login-input form-control'
                            type={seePassword ? 'text' : 'password'}
                            placeholder={translation.password}
                            autoComplete='current-password'
                            name='password'
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            minLength='6'
                            required
                        />
                        {seePassword ? <IoEyeOff className='icon-password' role="button" onClick={() => setSeePassword(false)} /> : <IoEye className='icon-password' role="button" onClick={() => setSeePassword(true)} />}
                    </div>
                    <Link className='btn btn-link text-decoration-none float-end' to='/reset-password'>{translation.password_forgotpassword}</Link>
                    <div className='d-flex justify-content-center mt-5'>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                            onChange={setCaptchaValue}
                            className='recaptcha-container'
                            ref={recaptchaRef}
                        />
                    </div>
                    <button className='btn green text-white w-100 mt-3 py-2' type='submit'>{translation.login}</button>

                </form>

                <p className='mt-3 text-center'>
                    {translation.by_logging_in} <span onClick={() => setShowTerms(true)}
                                                      style={{ cursor: 'pointer', color: 'blue' }}>{translation.terms_and_conditions}</span>.
                </p>
                <TermsModal show={showTerms} onHide={() => setShowTerms(false)} />

                <div className='d-flex justify-content-between mt-2'>
                    <Link className='btn blue text-white text-decoration-none' to='/signup'>{translation.register_cta}</Link>
                    <Link className='text-decoration-none align-self-center' to='/contactus'>{translation.contact_us_title}</Link>
                    
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    failedAuth: state.auth.failedAuth,
    translation: state.appState.translation
});

const mapDispatchToProps = dispatch => ({
    login: (email, password, captchaValue) => dispatch(login(email, password, captchaValue))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
