import React, {useEffect} from "react";
import {Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'leaflet/dist/leaflet.css';
import Dashboard from './containers/Dashboard';
import Login from './containers/Login';
import Signup from './containers/Signup';
import Activate from './containers/Activate';
import ResetPassword from './containers/ResetPassword';
import ResetPasswordConfirm from './containers/ResetPasswordConfirm';
import Google from "./containers/Google";
import Facebook from "./containers/Facebook";
import Events from "./containers/Events";
import Auth from "./components/Auth";
import Tournaments from "./containers/Tournaments";
import Tournament from "./containers/Tournament";
import Event from "./containers/Event";
import { Provider } from "react-redux";
import store from "./store";
import CreateEventStepOne from "./containers/CreateEventStepOne";
import CreateEventStepTwo from "./containers/CreateEventStepTwo";
import CreateEventStepThree from "./containers/CreateEventStepThree";
import UploadFish from "./containers/UploadFish";
import MyEventCatchMap from "./containers/MyCatchMap";
import MyEvents from "./containers/MyEvents";
import MyClub from "./containers/MyClub";
import SponsorsPage from "./containers/Sponsors";
import UserProfile from "./containers/UserProfile";
import Header from "./components/Header";
import SendClubInvitation from './components/SendClubInvitation';
import NotificationsPage from "./components/NotificationsPage";
import FormSuccess from './containers/FormSuccess';
import TermsAndConditions from "./containers/TermsAndConditions";
import ContactPage from "./containers/Contact";
import ContactPageNoAuth from "./containers/ContactNoAuth";
import TestPhotoUploadForm from "./containers/CheckDevicePermissionsSettings";
import EventOngoing from "./containers/EventOngoing";
import EventsOngoing from "./containers/EventsOngoing";
import {onForegroundMessageListener} from "./utils/firebase";
import EventValidate from "./containers/EventValidate";
import DeleteAccountInstructions from "./components/DeleteAccountInstructions";
import { createTheme, ThemeProvider } from "@mui/material";
import RankingMovement from "./components/RankingMovement";

const theme = createTheme({
  palette: {
    primary: {
      main: '#264653'
    }
  }
});

const App = () => {
  useEffect(() => {
    onForegroundMessageListener();
  }, []);
  return (
    <ThemeProvider theme={theme}>

    <Provider store={store}>
        <ToastContainer />
        <BrowserRouter>
            <Header withMenu />
            <Routes>
                <Route path="*" element={<Navigate to="/" replace />} />
                <Route exact path='/' element={<Auth><Dashboard /></Auth>} />
                <Route exact path='/login' element={<Login />} />
                <Route exact path='/signup' element={<Signup />} />
                <Route exact path='/facebook' element={<Facebook />} />
                <Route exact path='/google' element={<Google />} />
                <Route exact path='/reset-password' element={<ResetPassword />} />
                <Route exact path='/password/reset/confirm/:uid/:token' element={<ResetPasswordConfirm />} />
                <Route exact path='/activate/:uid/:token' element={<Activate />} />
                <Route exact path="/events" element={<Auth><Events /></Auth>} />
                <Route exact path="/tournaments" element={<Auth><Tournaments /></Auth>} />
                <Route exact path="/tournament" element={<Auth><Tournament /></Auth>} />
                <Route exact path="/event/:eventId" element={<Auth><Event /></Auth>} />
                <Route exact path="/create-event" element={<Auth><CreateEventStepOne /></Auth>} />
                <Route exact path="/create-fishscoring" element={<Auth><CreateEventStepTwo /></Auth>} />
                <Route exact path="/create-prizes" element={<Auth><CreateEventStepThree /></Auth>} />
                <Route exact path='/send-club-invitation' element={<Auth><SendClubInvitation  /></Auth>} />
                <Route exact path='/fish-upload' element={<Auth><UploadFish /></Auth>} />
                <Route exact path='/mymap' element={<Auth><MyEventCatchMap /></Auth>} />
                <Route exact path='/myevents' element={<Auth><MyEvents /></Auth>} />
                <Route exact path="/notifications" element={<Auth><NotificationsPage /></Auth>} />
                <Route exact path='/sponsors' element={<Auth><SponsorsPage /></Auth>} />
                <Route exact path='/club' element={<Auth><MyClub /></Auth>} />
                <Route exact path='/profile' element={<Auth><UserProfile /></Auth>} />
                <Route exact path='/success' element={<Auth><FormSuccess /></Auth>}/>
                <Route exact path="/contact" element={<Auth><ContactPage /></Auth>} />
                <Route exact path="/contactus" element={<ContactPageNoAuth />} />
                <Route exact path='/terms-and-conditions' element={<TermsAndConditions />} />
                <Route exact path='/checkdevice' element={<Auth><TestPhotoUploadForm /></Auth>} />
                <Route exact path='/events-ongoing' element={<Auth><EventsOngoing /></Auth>} />
                <Route exact path='/eventongoing/:eventId' element={<Auth><EventOngoing /></Auth>} />
                <Route exact path='/validate/:eventId' element={<Auth><EventValidate /></Auth>} />
                <Route exact path='/delete-account-instructions' element={<DeleteAccountInstructions />} />
                <Route exact path='/event/:eventId/live-ranking' element={<Auth><RankingMovement /></Auth>} />

            </Routes>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>

);
};

export default App;
