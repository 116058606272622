import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Modal from './Modal';
import getAxiosConfig from '../utils/axiosConfig';
import axios from 'axios';
import GeneralEditForm from './GeneralEditForm';
import FishScoringEditForm from './FishScoringEditForm';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const enrollButton = {
  'pending': {
    className: 'btn-light-blue',
    disabled: true,
  },
  'approved': {
    className: 'btn-light-green',
    disabled: true,
  },
  'rejected': {
    className: 'btn-light-red',
    disabled: true,
  },
  'not enrolled': {
    className: 'btn-light-blue',
    disabled: false,
  }
}

const EventActions = ({ selectedTab, translation, eventId, is_active, refreshEvent, is_owner, enrollment_status, is_ongoing, is_validator, event }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingStart, setIsLoadingStart] = useState(false);
  const [title] = useState([translation.edit_event, translation.edit_fishscoring, translation.edit_prizes])

  const publishEvent = useCallback(async () => {
    const config = getAxiosConfig();

    try {
        setIsLoading(true);
        await axios.post(`${process.env.REACT_APP_API_URL}/v2/event-publish/${eventId}/active/`, {}, config);
        toast.success('Event published');
        refreshEvent();
        setIsLoading(false);
    } catch (err) {
        const errMessage = err.response.data.detail;
        toast.error(errMessage);
        setIsLoading(false);
    }
  }, [eventId]);

  const handleEnrollClick = useCallback(async () => {
    try {
        setIsLoading(true);
        await axios.post(
            `${process.env.REACT_APP_API_URL}/v2/event/enrolments/enroll/`,
            { event: eventId },
            getAxiosConfig()
        );
        toast.success(`Event enrolled`);
        refreshEvent();
        setIsLoading(false);
    } catch (err) {
        const errMessage = err.response.data.message;
        toast.error(`${errMessage}`);
        setIsLoading(false);
    }
  }, [eventId]);

  const triggerStartStopEvent = useCallback(async () => {
    const trigger = is_ongoing ? 'stop' : 'start';

    try {
        setIsLoadingStart(true);
        await axios.post(
            `${process.env.REACT_APP_API_URL}/v2/event-status/${eventId}/${trigger}/`,
            {},
            getAxiosConfig()
        );
        toast.success(is_ongoing ? `Event stoped` : `Event started`);
        refreshEvent();
        setIsLoadingStart(false);
    } catch (err) {
        const errMessage = err.response.data.message;
        toast.error(`${errMessage}`);
        setIsLoadingStart(false);
    }
  }, [eventId, is_ongoing]);

  const renderTabAction = useCallback(() => {
    if(event.is_ended) return;
    switch(selectedTab) {
      case 0:
        return (<button className='btn btn-light-blue ms-2' onClick={() => {setOpen(true)}}>{translation.edit_event}</button>);
      case 1:
        return (<button className='btn btn-light-blue ms-2' onClick={() => {setOpen(true)}}>{translation.edit_fishscoring}</button>);
      default:
        return null;
    }
  });

return (<div>
    {is_owner && (
      <>
        <button
          className={`btn${is_active ? ' btn-light-green' : ' btn-light-blue'}`}
          onClick={() => publishEvent(eventId)} disabled={isLoadingStart || is_active}>
            {is_active ? translation.published : translation.publish}
        </button>
      </>
    )}
    {!event?.is_ended && (is_owner || is_validator) && (
        <button 
          className={`btn text-white ms-2 ${is_ongoing ? 'red' : 'green'}`}
          onClick={triggerStartStopEvent} disabled={isLoadingStart}>
            {is_ongoing ? translation.stop_event : translation.start_event}
        </button>
      )}
    {enrollment_status && !is_validator && !event?.is_ended && (
      <button
        className={`btn ms-2 ${enrollButton[enrollment_status].className}`}
        onClick={handleEnrollClick}
        disabled={enrollButton[enrollment_status].disabled}>
        {translation[enrollment_status]}
      </button>
    )}

    {is_ongoing && enrollment_status === 'approved' && (
        <button type="submit" className="btn green text-white submit-button ms-2" onClick={(e) => navigate(`/eventongoing/${event.id}`)}>
            {translation.dash_go_to_upload}
        </button>
      )}
    {/*  aici de adaugat pentru organizatori action sa valideze cei enrolati?*/}
    {is_owner && renderTabAction()}
    <Modal isOpen={open} onClose={() => setOpen(false)} title={title[selectedTab]}>
      {selectedTab === 0 && open ? 
        <GeneralEditForm onClose={() => setOpen(false)} refreshEvent={refreshEvent} /> :
        selectedTab === 1 && open ? 
        <FishScoringEditForm onClose={() => setOpen(false)} refreshEvent={refreshEvent} /> 
        : ''}
    </Modal>
  </div>);
}

const mapStateToProps = state => ({
    translation: state.appState.translation,
    event: state.eventState.event,
    user: state.auth.user,
});


export default connect(mapStateToProps, null)(React.memo(EventActions));