import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const UtilitiesEVents = ({ onSearch, translation, is_organiser, refreshList }) => {
    const navigate = useNavigate();

    return (
        <div className="col-12 mb-3 d-flex justify-items-left event-actions">
            <input
                className='input-event-actions form-control w-50'
                placeholder={translation.search}
                onChange={(e) => onSearch(e.target.value)}
            />

            <div className="form-check ms-2">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={refreshList} />
                <label className="form-check-label" for="flexCheckDefault">
                    {translation.my_events}
                </label>
            </div>

            { is_organiser && (
                <button className='btn green text-white ms-auto' onClick={() => {
                    navigate('/create-event')
                }}>Create Event</button>)
            }
        </div>
    );
};

const mapStateToProps = state => ({
    is_organiser: state.auth.is_organiser, 
    translation: state.appState.translation
});

export default connect(mapStateToProps, null)(React.memo(UtilitiesEVents));


